import axiosApi from '@/services/axiosApi'
import {ChatStateType, LanguageType} from '@/types'
import {logout} from '@/utils/authUser'
import {fetchWithCache} from "@/services/cacheApi"
import {formatDateToYMDTHisU} from '@/utils/dataTimeFormatter'
import moment from "moment/moment";
import {notifyChatMessage} from "@/utils/pusher";

export const chat = {
  namespaced: true,
  state: (): ChatStateType => ({
    isOpen: false,
    id: '',
    finished: false, // todo keep chat object
    messages: [],
    languages: [],
    translateLanguage: null,
    loader: true,
  }),
  getters: {
    getFinished: (state: ChatStateType) => state.finished,
    getIsLoading: (state: ChatStateType) => state.loader,
    getIsClosed: (state: ChatStateType) => !state.isOpen,
  },
  mutations: {
    loadingStart (state: ChatStateType) {
      state.loader = true
    },
    loadingEnd (state: ChatStateType) {
      state.loader = false
    },
    open (state: ChatStateType) {
      state.isOpen = true
    },
    close (state: ChatStateType) {
      state.isOpen = false
    },
    setLanguage (state: ChatStateType, lang:string|null) {
      state.translateLanguage = lang
    },
    setLanguages (state: ChatStateType, languages:Array<LanguageType>) {
      state.languages = languages
    },
    setChatId (state:ChatStateType, id:string|null) {
      state.id = id
    },
    setMessages (state:ChatStateType, data: []) {
      state.messages = data
    },
    resetMessages (state:ChatStateType) {
      state.messages = []
    },
    addMessage (state: ChatStateType, data: object) {
      state.messages.push(data)
    },
    updateMessage (state: ChatStateType, data: {index: number, payload: object}) {
      state.messages[data.index] = data.payload
    },
    startChat (state: ChatStateType) {
      state.finished = false
    },
    finishChat (state: ChatStateType) {
      state.finished = true
    },
    setFinished (state: ChatStateType, value: boolean) {
      state.finished = value
    },
  },
  actions: {
    async getMessages ({ commit }: { commit: (...args: any[]) => void }, payload: { id: string }) {
      axiosApi.get('client/messages/' + payload.id + '/list').then(response => {
        commit('setMessages', response.data.data)
        commit('setLanguage', response.data.language)
        commit('loadingEnd')
      })
    },
    async updateMessages({ commit, state }: { commit: (...args: any[]) => void, state: ChatStateType }, payload: { id: string }) {
      const params: { lastMessageId: null | string } = {
        lastMessageId: null
      }

      if (state.messages.length > 0) {
        const lastMessage = state.messages[state.messages.length - 1]

        params.lastMessageId = lastMessage.id
      }

      axiosApi.get('client/messages/' + payload.id + '/list', { params }).then(response => {
        const newMessages = response.data.data;

        if (newMessages.length > 0) {
          newMessages.map((message: any) => {commit('addMessage', message)})

          notifyChatMessage()
        }
      })
    },
    changeLanguage({ commit }: { commit: (...args: any[]) => void }, payload: { chatId: string, data : {languageId : string}}) {
      axiosApi.post('client/' + payload.chatId + '/change-language', payload.data).then(
        response => {
          // commit('setLanguage', response.data.data)
        })
    },
    getLanguages: async ({commit}: { commit: (...args: any[]) => void }) => {
      const {data} = await fetchWithCache(`${process.env.VUE_APP_API_URL}languages`, 'l_cache')
      commit('setLanguages', data)
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sendMessage ({ commit }: { commit: (...args: any[]) => void }, payload: {
      // eslint-disable-next-line camelcase
      chat_id : string,
      // eslint-disable-next-line camelcase
      parent_message_id : string,
      // eslint-disable-next-line camelcase
      from_id : string,
      // eslint-disable-next-line camelcase
      to_id : string
      message : string,
      index: number
    }) {
      axiosApi.post('client/send-message', {
        chat_id: payload.chat_id,
        parent_message_id: payload.parent_message_id,
        from_id: payload.from_id,
        to_id: payload.to_id,
        message: payload.message,
        sent_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      }).then((res: any) => {
        if (res?.response?.status === 422) {
          commit('setFinished', true)
        }
        if (res.status === 200) {
          commit('updateMessage', { index: payload.index, payload: res.data.data })
        }
      })
    },
    submitReview ({ commit }: { commit: (...args: any[]) => void }, payload: {
      chatId : string,
      rate : number,
      message : string,
    }) {
      axiosApi.post('client/' + payload.chatId + '/review', {
        star: payload.rate,
        description: payload.message,
      })
      .then(response => {
        if (response.status === 204) {
          commit('startChat')
          commit('close')
          logout()
        }
      })
      .catch(error => {
        console.error('Error:', error)
        commit('startChat')
        commit('close')
        logout()
      })
    },
    cancelReview ({ commit }: { commit: (...args: any[]) => void }, payload: {
      chatId: string,
    }) {
      axiosApi.post('client/' + payload.chatId + '/review/cancel')
        .then(response => {
          if (response.status === 204) {
            commit('startChat')
            commit('close')
            logout()
          }
        })
        .catch(error => {
          console.error('Error:', error)
          commit('startChat')
          commit('close')
          logout()
        })
    },
    messageSeen: async ({ commit }: { commit: (...args: any[]) => void }, payload: {
      chatId: string,
    }) => {
      axiosApi.post('client/' + payload.chatId + '/message-seen', {
        'date_time': formatDateToYMDTHisU(new Date())
      })
        .then(response => {
          // console.log(response.status, 'message-seen')
        })
    },
  }
}
