<template>
  <router-view/>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { initFlowbite } from 'flowbite'
import { initChat, updateChat } from '@/utils/initChat'
import { initEcho } from '@/utils/pusher'
import { authenticate, getToken } from '@/utils/authUser'
import { getCompanyId } from '@/utils/routerParams'
import { debounce } from 'lodash';
import { isMobile } from '@/utils/isMobile'

let timeout: number | null = null;  // Store timeout ID to clear it when necessary

onMounted(async () => {
  const token = getToken();
  const companyId = getCompanyId();

  if(token.access_token && companyId) {
    await authenticate(token, companyId)
    initEcho()
    initChat()
  }

  if (isMobile()) {
    window.addEventListener('focus', handleTabFocus)
  }
})

const debouncedUpdateChat = debounce(updateChat, 1000);

const handleTabFocus = async () => {
  debouncedUpdateChat();
}

const getUserAgent = (device: string): boolean => {
  return navigator.userAgent.indexOf(device) > -1;
}

const addViewport = () => {
  if(getUserAgent('iPhone')) {
    const viewport: HTMLMetaElement | null = document.querySelector("meta[name=viewport]");
    if (viewport) {
      viewport.setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1");
    }
  }
}

onMounted(() => {
  addViewport()
  initFlowbite()
})

</script>
