import { createEcho } from '@/services/createEcho'
import { getToken, getUserId } from '@/utils/authUser'
import { getChatId } from '@/utils/initChat'
import store from '@/store'
import eventBus from '@/services/eventBus'
import { browserNotify } from '@/utils/browserNotify'


const finishChatHandler = () => {
  store.commit('chat/finishChat')
  store.commit('chat/resetMessages')
  window.Echo.disconnect()
}

const notifyChatMessage = () => {
  eventBus.emit('messageListUpdated');

  if(store.getters['chat/getIsClosed']){
    eventBus.emit('toggleAudioMute', { mute: true, isPLaying: true });
    store.commit('user/setHasNewMessages', true)
  } else {
    store.dispatch('chat/messageSeen', {chatId: getChatId()})
    store.commit('user/setHasNewMessages', false)
  }
  browserNotify()
}

const chatMessageHandler = (data:any):void => {
  if (data.messageResource) {
    store.commit('chat/addMessage', data.messageResource)
    notifyChatMessage()
  }
}

const initEcho = () => {
  const token = getToken()
  if(token.access_token) {
    window.Pusher = require('pusher-js')
    window.Echo = createEcho(token.access_token)

    window.Echo.private('real-time.' + getUserId())
      .listen('.finish-chat', finishChatHandler)
      .listen('.chat', chatMessageHandler)

  }
}

export { initEcho, notifyChatMessage }
