import axiosApi from "@/services/axiosApi";

export const survey = {
  namespaced: true,
  state: {
    surveyItems: [],
  },
  mutations: {
    setSurveyItems: (state: any, surveyItems: any) => state.surveyItems = surveyItems
  },
  getters: {
    getSurveyItems: (state: any) => state.surveyItems
  },
  actions: {
    loadItems: async ({ commit }: { commit: (...args: any[]) => void }, payload: {
      id: string,
    }) => {
      axiosApi.get(`/survey/${payload.id}`)
        .then(response => {
          let items  = []
          if(response.status === 200) {
            items = response.data.data
          }
          commit('setSurveyItems', items)
        })
    },
    submitSurvey: async ({ commit }: { commit: (...args: any[]) => void }, payload: {
      id: string,
      comment: string,
      survey_items: any,
      onSuccess: (...args: any[]) => void,
      onFailure: (...args: any[]) => void,
    }) => {
      axiosApi.post(`/client/${payload.id}/survey`, {
        survey_items: payload.survey_items,
        comment: payload.comment
      }).then((response: any) => {
        if (!response) {
          return payload.onFailure(['Something went wrong']);
        }

        if (response?.status === 204) {
          return payload.onSuccess();
        }

        const errors = response.response?.data?.errors;
        if (errors && Array.isArray(errors)) {
          return payload.onFailure(errors);
        }

        payload.onFailure(['An unknown error occurred']);
      })
    },
    submitOrderSurvey: async ({ commit }: { commit: (...args: any[]) => void }, payload: {
      id: string,
      orderId: string,
      comment: string,
      survey_items: any,
      onSuccess: (...args: any[]) => void,
      onFailure: (...args: any[]) => void,
    }) => {
      axiosApi.post(`/client/${payload.orderId}/survey-order-response`, {
        survey_items: payload.survey_items,
        comment: payload.comment
      }).then((response: any) => {

        if (!response) {
          return payload.onFailure(['Something went wrong']);
        }

        if (response?.status === 204) {
          return payload.onSuccess();
        }

        const errors = response.response?.data?.errors;

        if (errors) {
          Object.keys(errors).some((key) => {
            const typedKey = key as keyof typeof errors;
            payload.onFailure(errors[typedKey]);
            return true;
          });
        } else {
          payload.onFailure(['An unknown error occurred']);
        }
      })
    }
  }
}
